import React, { Component } from 'react';
import { PageHeader, Button, Glyphicon } from 'react-bootstrap';
import AppListContainer from './app-list-container';
import CreateApp from './create-app';
import { connect } from 'react-redux';
import { saveApp } from '../data/actions';

class DashboardComponent extends Component {

  constructor(props) {
    super(props);

    this.dispatch = this.props.dispatch;

    this.state = {
      isCreatingApp: false
    };
  }

  showCreateAppDialog = () => {
    this.setState({isCreatingApp: true});
  }

  createApp = (formData) => {
    return this.dispatch(saveApp(formData))
      .then(() => {
        this.setState({
          isCreatingApp: false
        });
      });
  }
  
  cancelCreateApp = () => {
    this.setState({
      isCreatingApp: false
    });
  }

  render () {
    return (
       <div>
        <PageHeader>
          Apps
          <Button bsStyle="success" className="pull-right" onClick={this.showCreateAppDialog}>
            <Glyphicon glyph="plus" /> New app
          </Button>
        </PageHeader>
        <AppListContainer />
        <CreateApp show={this.state.isCreatingApp} save={this.createApp} cancel={this.cancelCreateApp} />
       </div>
    );
  }
}

export default connect()(DashboardComponent);