import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authHandler from './handler';

export default function AuthenticatedRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        authHandler.isAuthenticated() ? (
          Component ? <Component {...props} /> : render(props)
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
