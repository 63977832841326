import React from 'react';

export default () => {

    return (
        <div className="footer">
            <div className="container footer-content">
                <span style={{ "align-self": "center" }}>&copy; {new Date().getFullYear()} Mothership</span>
                <span style={{"flex": "1"}}></span>
                <a href="https://mydata.rownd.io/app/293632549189583364" target="_blank">
                    <img src="https://app.rownd.io/images/Manage-my-data-btn-01.svg" alt="" style={{"width":"150px", "height": "auto"}} />
                </a>
            </div>
        </div>
    )
}