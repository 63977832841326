import React, { Component } from 'react';
import Time from 'react-time';
import { Table, Glyphicon } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import DeleteModal from '../common/delete-modal';

export default class AppList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDeletingApp: false,
      selectedApp: {}
    };
  }

  showDeleteAppDialog = (app) => {
    this.setState({
      isDeletingApp: true,
      selectedApp: app
    });
  }

  deleteApp = () => {
    this.setState({ isDeletingApp: false });
    return this.props.deleteApp(this.state.selectedApp.slug);
  }

  render () {
    return (
      <div>
         <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Last modified</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              { this.props.apps.isFetching && <tr><td colSpan="3">Loading...</td></tr> }
              { !this.props.apps.isFetching && this.props.apps.items.length === 0 && <tr><td>No apps found</td></tr> }
              {
                this.props.apps.items.map(app => {
                  return (
                    <tr key={app.slug}>
                      <td>
                        <LinkContainer to={`/apps/${app.slug}`}>
                          <a>{app.name}</a>
                        </LinkContainer>
                      </td>
                      <td>{app.description}</td>
                      <td><Time value={app.modifiedAt} relative /></td>
                      <td>
                        <a className="pull-right delete-link icon-link" onClick={this.showDeleteAppDialog.bind(this, app)}>
                          <Glyphicon glyph="remove" /> Delete
                        </a>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>

          { this.state.isDeletingApp &&
            <DeleteModal show={this.state.isDeletingApp} name={this.state.selectedApp.name} type="app" onConfirm={this.deleteApp} />
          }
        </div>
    );
  }
}