import React, { Component } from 'react';
import Time from 'react-time';
import { PageHeader, Button, Glyphicon, Table, Tooltip, OverlayTrigger, Modal, Alert, Well } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import CreateEnv from './create-env';
import DeleteModal from '../common/delete-modal';

class AppEnvironmentList extends Component {

  constructor(props) {
    super(props);

    this.dispatch = this.props.dispatch;

    this.state = {
      isCreatingEnv: false,
      isDeletingEnv: false,
      isRegenerateModalOpen: false,
      selectedKey: void 0
    };
  }

  showCreateEnvDialog = () => {
    this.setState({isCreatingEnv: true});
  }
  
  cancelCreateEnv = () => {
    this.setState({isCreatingEnv: false});
  }

  showDeleteEnvDialog = (env) => {
    this.setState({
      isDeletingEnv: true,
      selectedEnv: env
    });
  }

  createEnv = (formData) => {
    var self = this;

    return this.props.onSave('config', Object.assign({
      app: this.props.app.slug
    }, formData))
    .then(() => {
      self.state.isCreatingEnv = false;
      self.props.invalidateApp();
      self.props.fetchApp(self.props.app.slug);
    });

  }

  deleteEnv = () => {
    var self = this;

    return this.props.deleteConfig(this.props.app.slug, this.state.selectedEnv)
    .then(() => {
      self.state.isDeletingEnv = false;
      self.props.invalidateApp();
      self.props.fetchApp(self.props.app.slug);
    });
  }
  
  onRegenerateClick = (key) => {
        this.setState({ 
            isRegenerateModalOpen: true,
            selectedKey: key
        });
    }
    
  closeRegenerateModal = () => {
      this.setState({
          isRegenerateModalOpen: false,
          selectedKey: void 0
      });
  }
  
  regenerateKey = () => {
    this.props.regenerateKey(this.props.app.slug, this.state.selectedKey.env)
      .then(() => {
        this.closeRegenerateModal();
      });
  }

  render () {
    const regenerateTooltip = (
      <Tooltip>Regenerate key</Tooltip>
    );
    
    let {
      app,
      keys
    } = this.props;
    
    let {
      isRegenerateModalOpen,
      selectedKey
    } = this.state;
    
    const baseEnvKeyInfoTooltip = (
      <Tooltip>
        Base environments have no key.
      </Tooltip>
    );

    return (
        <div>
          <h3>Environments</h3>
          <Table responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Key</th>
                <th>Last modified</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              { this.props.app.isFetching && <tr><td colSpan="3">Loading...</td></tr> }
              { !this.props.app.isFetching && this.props.app.configs.length === 0 && <tr><td>No environments found</td></tr> }
              {
                this.props.app.configs.map(config => {
                  return (
                    <tr key={config.env}>
                      <td>
                        <LinkContainer to={`/apps/${this.props.app.slug}/configs/${config.env}`}>
                          <a>{config.env}</a>
                        </LinkContainer>
                      </td>
                      <td>
                        {config.env !== 'base' &&
                          <div>
                            { keys.items[config.env] ? keys.items[config.env].key : <em>No key</em> }
                            <OverlayTrigger placement="top" overlay={regenerateTooltip}>
                              <a className="delete-link icon-link" style={{paddingLeft: '5px'}} onClick={this.onRegenerateClick.bind(this, keys.items[config.env])}>
                                <Glyphicon glyph="refresh" />
                              </a>
                            </OverlayTrigger>
                          </div>
                        }
                        
                        {config.env === 'base' &&
                          <div>
                            <span>None </span>
                            <OverlayTrigger placement="right" overlay={baseEnvKeyInfoTooltip}>
                              <Glyphicon glyph="info-sign" />
                            </OverlayTrigger>
                          </div>
                        }
                      </td>
                      <td><Time value={config.modifiedAt} relative /></td>
                      <td>
                        {config.env !== 'base' &&
                        <a className="pull-right delete-link icon-link" onClick={this.showDeleteEnvDialog.bind(this, config.env)}>
                          <Glyphicon glyph="remove" /> Delete
                        </a>
                        }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
          <div style={{ overflow: 'auto', paddingBottom: '10px' }}>
            <Button bsStyle="success" className="pull-right" onClick={this.showCreateEnvDialog}>
              <Glyphicon glyph="plus" /> Add environment
            </Button>
          </div>
          
          <Well>
            The following key can access any environment config: <strong>{keys.appKey ? keys.appKey.key : 'No key'}</strong>
            <OverlayTrigger placement="top" overlay={regenerateTooltip}>
              <a className="delete-link icon-link" style={{paddingLeft: '5px'}} onClick={this.onRegenerateClick.bind(this, keys.appKey)}>
                <Glyphicon glyph="refresh" />
              </a>
            </OverlayTrigger>
          </Well>
          
          <CreateEnv show={this.state.isCreatingEnv} save={this.createEnv} cancel={this.cancelCreateEnv} />
          <DeleteModal show={this.state.isDeletingEnv} name={this.state.selectedEnv} type="environment" onConfirm={this.deleteEnv} />
          
          {isRegenerateModalOpen &&
            <Modal show={isRegenerateModalOpen} onHide={this.closeRegenerateModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Regenerate key confirmation</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <p>Are you sure you want to regenerate the {selectedKey.type} key for <strong>{selectedKey.type === 'app' ? app.name : selectedKey.env}</strong>?</p>
                        
                        <Alert bsStyle="danger">
                            <p>DANGER: Regenerating this key will revoke access to all currently connected clients.
                            You <strong>must</strong> provide the new key to any clients requiring continued access and then restart them.</p>
                        </Alert>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="link" onClick={this.closeRegenerateModal}>Cancel</Button>
                    <Button bsStyle="danger" onClick={this.regenerateKey}>Regenerate</Button>
                </Modal.Footer>
            </Modal>
        }
        </div>
    );
  }
}

export default AppEnvironmentList;