import React, { Component } from 'react';
import { connect } from 'react-redux';
import Time from 'react-time';
import { fetchKeys } from '../data/actions';
import { Table, Glyphicon, Modal, Alert, Button } from 'react-bootstrap';

class KeysDashboard extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            appKeys: [],
            envKeys: [],
            isRegenerateModalOpen: false,
            selectedKey: void 0
        };
        
        props.fetchKeys(this.props.app.slug);
    }
    
    componentWillReceiveProps = (props) => {
        if (props.app.slug !== this.props.app.slug) {
            this.props.fetchKeys(props.app.slug);
        } else if (props.keys) {
            this.filterKeysList(props.keys);
        }
    }
    
    filterKeysList = (keys) => {
        if (!keys) { return; }
        
        let newState = {};
        
        newState.appKeys = keys.items.filter(key => key.type === 'app');
        newState.envKeys = keys.items.filter(key => key.type === 'env');
        
        this.setState(newState);
    }
    
    onRegenerateClick = (key) => {
        this.setState({ 
            isRegenerateModalOpen: true,
            selectedKey: key
        });
    }
    
    closeRegenerateModal = () => {
        this.setState({
            isRegenerateModalOpen: false,
            selectedKey: void 0
        });
    }
    
    regenerateKey = () => {
        
    }
    
    render() {
        let {
            appKeys,
            envKeys,
            isRegenerateModalOpen,
            selectedKey
        } = this.state;
        
        let {
            selectedApp
        } = this.props;
        
        return (
            <div>
                <p>Use these keys to enable Maestro clients to retrieve the desired configuration.</p>
                
                <h3>App keys</h3>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Last updated</th>
                            <th></th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    { appKeys.map(keyItem => {
                        return (
                            <tr key={keyItem.key}>
                                <td>{keyItem.key}</td>
                                <td><Time value={keyItem.modifiedAt} relative /></td>
                                <td>
                                    <a className="pull-right delete-link icon-link" onClick={this.onRegenerateClick.bind(this, keyItem)}>
                                      <Glyphicon glyph="refresh" /> Regenerate
                                    </a>
                                </td>
                            </tr>
                        );
                    }) }
                    </tbody>
                </Table>
                
                <h3>Environment keys</h3>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Key</th>
                            <th>Environment</th>
                            <th>Last updated</th>
                            <th></th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    { envKeys.map(keyItem => {
                        return (
                            <tr key={keyItem.key}>
                                <td>{keyItem.key}</td>
                                <td>{keyItem.env}</td>
                                <td><Time value={keyItem.modifiedAt} relative /></td>
                                <td>
                                    <a className="pull-right delete-link icon-link" onClick={this.onRegenerateClick.bind(this, keyItem)}>
                                      <Glyphicon glyph="refresh" /> Regenerate
                                    </a>
                                </td>
                            </tr>
                        );
                    }) }
                    </tbody>
                </Table>
                
                {isRegenerateModalOpen &&
                    <Modal show={isRegenerateModalOpen} onHide={this.closeRegenerateModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Regenerate key confirmation</Modal.Title>
                        </Modal.Header>
        
                        <Modal.Body>
                            <div>
                                <p>Are you sure you want to regenerate the {selectedKey.type} key for <strong>{selectedKey.type === 'app' ? selectedApp.name : selectedKey.env}</strong>?</p>
                                
                                <Alert bsStyle="danger">
                                    <p>DANGER: Regenerating this key will revoke access to all currently connected clients.
                                    You <strong>must</strong> provide the new key to clients that should continue having access and then restart them.</p>
                                </Alert>
                            </div>
                        </Modal.Body>
        
                        <Modal.Footer>
                            <Button bsStyle="link" onClick={this.closeRegenerateModal}>Cancel</Button>
                            <Button bsStyle="danger" onClick={this.regenerateKey}>Regenerate</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </div>
        );
    }
    
}

function mapStateToProps(state) {
    return {
        selectedApp: state.selectedApp,
        keys: state.keys
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchKeys: (appSlug) => dispatch(fetchKeys(appSlug))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeysDashboard);