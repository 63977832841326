import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router';
import history from './history';
import {StripeProvider} from 'react-stripe-elements';
import {IntlProvider} from 'react-intl';
import {Provider} from 'react-redux';
import configureStore from '../data/store.jsx';
import App from './app.jsx';
import Dashboard from '../apps/dashboard';
//import Login from '../account/login.jsx';
//import Registration from '../account/register.jsx';
//import ForgotPassword from '../account/forgot-password.jsx';
//import Profile from '../account/profile.jsx';
import Account from '../account';
import AppDetailContainer from '../apps/app-detail-container';
import AppConfigEditor from '../apps/app-config-editor';
import AppEnvironmentList from '../apps/app-environment-list';
import AppComponent from '../apps';
import AccountBilling from '../account/billing.jsx';
import AccountInvoices from '../account/invoices.jsx';
import AuthComponent from '../account/auth.jsx';

const store = configureStore();

/*ReactStormpath.init({
  endpoints: {
    //baseUri: '/'
  },

  dispatcher: {
    type: 'redux',
    store
  }
});*/

export default class AppRouter extends React.Component {
    render() {
        return(
          <IntlProvider locale="en">
          <StripeProvider apiKey={ window.stripeApiKey }>
          <Provider store={store}>
            <Router history={history}>
              <Switch>
                <Route path="/auth/:action" component={AuthComponent} />
                <Route path="*" component={App} />
              </Switch>
              {/*<Route path="/" component={App}>
                
                <LoginRoute path="login" component={Login} />
                <LogoutRoute path="logout" />
                <Route path='register' component={Registration} />
                <Route path='forgot' component={ForgotPassword} />
                <Route path="/auth/:action" component={AuthComponent} />
                <AuthenticatedRoute>
                  <Route path='/apps' component={AppComponent}>
                    <IndexRoute component={Dashboard} />
                    <Route path=':app' component={AppDetailContainer}>
                      <IndexRoute component={AppEnvironmentList} />
                      <Route path='configs/:config' component={AppConfigEditor} />
                    </Route>
                  </Route>
                </AuthenticatedRoute>
              </Route>*/}
            </Router>
          </Provider>
          </StripeProvider>
          </IntlProvider>
        );
    }
}
