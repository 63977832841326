import React from 'react';
import { Grid } from 'react-bootstrap';
import { Route, Switch } from 'react-router-dom';

import Dashboard from './dashboard';
import AppDetailContainer from './app-detail-container';

export default ({ children }) =>
  <Grid>
    <Switch>
      <Route path='/apps/:app/configs/:config' component={AppDetailContainer} />
      <Route path='/apps/:app' component={AppDetailContainer} />
      <Route path='*' component={Dashboard} />
    </Switch>
  </Grid>;
