import React, {Component, PropTypes} from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import {CardElement, injectStripe} from 'react-stripe-elements';

class NewCard extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showModal: props.show || false,
            saving: false,
            error: void 0
        });
    }

    componentWillReceiveProps = function(nextProps) {
      this.setState({
        showModal: nextProps.show || false
      });
    }

    close = (e) => {
        this.setState({
            showModal: false,
            error: void 0
        });
    }
    
    cancel = () => {
        if (this.props.cancel) {
            this.props.cancel();
        }

        this.close();
    }

    submit = (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();
        ev.stopPropagation();
        
        this.setState({ saving: true });

        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        this.props.stripe.createToken().then(async ({token}) => {
            try {
                await this.props.handleSaveCard(token);
                this.close();
            } catch (err) {
                this.setState({
                    error: err.response && err.response.status === 400 ? err.response.status : err.message,
                    saving: false
                });
            }
        });

    }

    render() {
        return (
            <Modal show={this.state.showModal} onHide={this.cancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a payment method</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Enter your debit or credit card number, expiration date, security code, and zip code below.</p>
                    <form>
                        <div className="stripe-card-container">
                            <CardElement />
                        </div>
                    </form>

                    <p>By adding this payment method, you are authorizing us to charge your card each month based on your usage of the service.</p>
                    
                    {this.state.error && <Alert bsStyle="danger">
                        <strong>Error: </strong>
                        { this.state.error === 400 && <span>Your card details couldn't be validated. Please check them and try again.</span> }
                        { typeof this.state.error === 'string' && this.state.error }
                    </Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="link" onClick={this.cancel}>Cancel</Button>
                    <Button bsStyle="primary" onClick={this.submit} disabled={this.state.saving}>{this.state.saving ? 'Adding...' : 'Add card'}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default injectStripe(NewCard);