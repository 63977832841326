import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { UserProfileForm } from 'react-stormpath';
import { PageHeader, Row, Col, Button, FormGroup } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { userActions } from '../data/actions';

function Profile() {

  let user = useSelector(state => state.user.profile);
  let dispatch = useDispatch();

  function onSubmit(values) {
    dispatch(userActions.creators.update(values));
  }

  return (
    <div>
      <Row>
        <Col s={12}>
          <PageHeader>Edit profile</PageHeader>
          <p>Edit your personal information or change your password.</p>
        </Col>
      </Row>

      <Form
        initialValues={user}
        onSubmit={onSubmit}
        // validate={validate}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <label>First name</label>
              <Field name="first_name" component="input" placeholder="First name" className="form-control" />
            </FormGroup>
            <FormGroup>
              <label>Last name</label>
              <Field name="last_name" component="input" placeholder="Last name" className="form-control" />
            </FormGroup>
            <FormGroup>
              <label>Email</label>
              <Field name="email" component="input" placeholder="Email" className="form-control" />
            </FormGroup>
            <div className="buttons">
              <Button type="submit" disabled={submitting || pristine}>
                Submit
            </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default Profile;