import React, { Component, PropTypes } from 'react';
import {Navbar, NavbarHeader, NavbarBrand, Nav, NavItem, Dropdown, MenuItem, Image, Button, FormGroup} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import auth from '../auth/handler';

const avatarStyle = {
  display: 'inline',
  marginRight: '10px'
};

function Toolbar(props) {

    var userMenuToggle = <Dropdown.Toggle useAnchor>User loading...</Dropdown.Toggle>;

    if (props.user.profile !== null) {
      userMenuToggle = (
        <Dropdown.Toggle useAnchor>
          <Image src={props.user.profile.photo + '?s=24'} circle responsive style={avatarStyle} />
          {props.user.profile.email}
        </Dropdown.Toggle>
      );
    }

    return (
      <Navbar>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/">
              <img alt="Mothership" src="/img/mothership-logo.svg" />
            </Link>
          </Navbar.Brand>
        </Navbar.Header>
        <Nav>
          <NavItem eventKey={1} href="https://docs.mothership.cloud" target="_blank">Docs</NavItem>
          <NavItem eventKey={1} href="https://support.mothership.cloud/portal/newticket" target="_blank">Support</NavItem>
        </Nav>

          { !auth.isAuthenticated() &&
            <Nav pullRight>
              <NavItem><LinkContainer to="/login"><span>Log in</span></LinkContainer></NavItem>
              <NavItem><LinkContainer to="/register"><span className="btn-sign-up">Sign up</span></LinkContainer></NavItem>
            </Nav>
          }

          { auth.isAuthenticated() &&
            <Nav pullRight>
            <Dropdown id="basic-nav-dropdown" componentClass="li">
              {userMenuToggle}
              <Dropdown.Menu>
                <LinkContainer to="/apps">
                  <MenuItem eventKey={3.1}>Dashboard</MenuItem>
                </LinkContainer>

                <LinkContainer to="/account/profile">
                  <MenuItem eventKey={3.2}>Account settings</MenuItem>
                </LinkContainer>

                <MenuItem divider />

                <LinkContainer to="/auth/logout">
                  <MenuItem eventKey={3.3}>Sign out</MenuItem>
                </LinkContainer>
              </Dropdown.Menu>
            </Dropdown>
              </Nav>
          }
      </Navbar>
    );
  }
  
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(
  mapStateToProps
)(Toolbar);