/* global localStorage */

import auth0 from 'auth0-js';
import _get from 'lodash/get';
import { getQueryVariable } from '../data/utils';

const baseDomainUrl = `${window.location.protocol}//${window.location.host}`;

class Auth {
  auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'mothership-cloud.auth0.com',
    clientID: process.env.REACT_APP_AUTH0_CLIENTID || 'KoUHXB7WOh2zVDCr9lDLzy48XndaOsPY',
    redirectUri: `${baseDomainUrl}/auth/callback`,
    responseType: 'token id_token',
    scope: 'openid profile email',
    audience: 'https://api.mothership.cloud'
  });

  login = (loc) => {
    this.auth0.authorize({
      state: JSON.stringify({
        originalUrl: `${loc.pathname}${loc.search}`
      })
    });
  }
  
  handleAuthentication = (cb) => {
    const state = getQueryVariable('state', _get(window, 'location.hash', '').substr(1));
    this.auth0.parseHash({ state }, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);

        let stateObj = {};
        if (state && state.startsWith('{')) {
          stateObj = JSON.parse(state);
        }

        cb && cb(stateObj.originalUrl);
      } else if (err) {
        console.log(err);
        throw new Error(`Failed to authenticate the user. Error: ${err.error}`);
      }
    });
  }

  getAccessToken = () => {
    return this.accessToken;
  }

  getIdToken = () => {
    return this.idToken;
  }

  setSession = (authResult) => {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');

    // Set the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
  }

  renewSession = () => {
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult);
       } else if (err) {
         this.logout();
         console.log(err);
         alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
       }
    });
  }

  logout = () => {
    // Remove tokens and expiry time
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    localStorage.removeItem('isLoggedIn');
    
    this.auth0.logout({
      clientID: process.env.REACT_APP_AUTH0_CLIENTID || 'KoUHXB7WOh2zVDCr9lDLzy48XndaOsPY',
      returnTo: `${baseDomainUrl}`
    });
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }
}

export default new Auth();
export {Auth};
