import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchApps, deleteApp } from '../data/actions';
import AppList from './app-list';

class AppListContainer extends Component {

  componentDidMount() {
    this.props.fetchApps();
  }

  render() {
    return <AppList apps={this.props.apps} deleteApp={this.props.deleteApp} />;
  }

}

const mapStateToProps = (state) => {
  return {
    apps: state.apps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteApp: (slug) => dispatch(deleteApp(slug)),
    fetchApps: () => dispatch(fetchApps())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppListContainer);