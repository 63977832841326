import React, { Component, PropTypes } from 'react';
import Time from 'react-time';
import { PageHeader, Button, Glyphicon, Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import AuthenticatedRoute from '../auth/authenticated-route';
import Keys from './keys';
import CreateApp from './create-app';
import AppConfigEditor from './app-config-editor';
import AppEnvironmentList from './app-environment-list';

class AppDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isEditingApp: false
    };

  }

  componentDidMount() {
    this.handleFetch(this.props);
  }

  componentWillReceiveProps(props) {
    this.handleFetch(props);
  }

  handleFetch(props) {
    const {fetchApp, fetchConfig, fetchKeys, app: selectedApp, config: selectedConfig} = props;
    const {app, config} = props.match.params;

    if (app && selectedApp.slug !== app) {
      fetchApp(app);
      fetchKeys(app);
    }

    if (config && selectedConfig.env !== config) {
      fetchConfig(app, config);
    }
  }

  onSave = (type, data) => {
    switch (type) {
      case 'app':
        return this.props.saveApp(data).then(() => {
          //this.props.invalidateApp();
          //this.props.fetchApp(data.slug);
          this.props.selectApp(data);
        });

      case 'config':
        return this.props.saveConfig(data);
    }
  }

  onEditApp = () => {
    this.setState({
      isEditingApp: true
    });
  }

  onSaveApp = (details) => {
    let app = Object.assign({}, this.props.app, {
      name: details.name,
      description: details.description
    });

    this.onSave('app', app);

    this.setState({
      isEditingApp: false
    });
  }

  render () {

    const childProps = {
      app: this.props.app,
      config: this.props.config,
      onSave: this.onSave,
      fetchApp: this.props.fetchApp,
      invalidateApp: this.props.invalidateApp,
      deleteConfig: this.props.deleteConfig,
      keys: this.props.keys,
      fetchKeys: this.props.fetchKeys,
      regenerateKey: this.props.regenerateKey
    };

    /*const childrenWithProps = React.Children.map([
      <AuthenticatedRoute path='/apps/:app' component={AppEnvironmentList} />,
      <AuthenticatedRoute path='/apps/:app/configs/:config' component={AppConfigEditor} />
    ],
     (child) => React.cloneElement(child, {
       app: this.props.app,
       config: this.props.config,
       onSave: this.onSave,
       fetchApp: this.props.fetchApp,
       invalidateApp: this.props.invalidateApp,
       deleteConfig: this.props.deleteConfig,
       keys: this.props.keys,
       fetchKeys: this.props.fetchKeys,
       regenerateKey: this.props.regenerateKey
     })
    );*/

    const { params : routeParams } = this.props.match;

    return (
        <div>
          <Breadcrumb>
            <Breadcrumb.Item componentClass="span">
              <Link to="/apps">Dashboard</Link>
            </Breadcrumb.Item>
            {!routeParams.config && <Breadcrumb.Item active>{this.props.app.name}</Breadcrumb.Item>}
            {routeParams.config &&
              <Breadcrumb.Item componentClass="span">
                <Link to={`/apps/${this.props.app.slug}`}>{this.props.app.name}</Link>
              </Breadcrumb.Item>
            }
            {routeParams.config && <Breadcrumb.Item active>{this.props.config.env}</Breadcrumb.Item>}
          </Breadcrumb>
          
          <div style={{ display: 'grid', gridTemplateColumns: 'auto 100px' }}>
            <PageHeader>
                {this.props.app.name}
                <br />
                <small>{this.props.app.description}</small>
            </PageHeader>
            <PageHeader style={{alignSelf: 'end'}}>
              <Button bsStyle="default" className="pull-right" onClick={this.onEditApp}>
                <Glyphicon glyph="pencil" /> Edit app
              </Button>
            </PageHeader>
          </div>
          
          <Switch>
            <AuthenticatedRoute path='/apps/:app/configs/:config' render={(props) => <AppConfigEditor {...props} {...childProps} />} />
            <AuthenticatedRoute path='/apps/:app' render={(props) => <AppEnvironmentList {...props} {...childProps} />} />
          </Switch>

          <CreateApp show={this.state.isEditingApp} save={this.onSaveApp} app={this.props.app} />
        </div>
    );
  }
}

export default AppDetails;