import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Modal, FormControl, Button, ControlLabel, FormGroup } from 'react-bootstrap';

class DeleteModal extends Component {
    
    static propTypes = {
        onConfirm: PropTypes.func.isRequired,
        show: PropTypes.bool,
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);

        this.state = this.setupDialog(props);
    }

    getResetState = () => {
        return {
            type: '',
            name: ''
        };
    }

    componentWillReceiveProps = function(nextProps) {
      this.setState(this.setupDialog(nextProps));
    }
    
    setupDialog = function(props) {
        return {
          showModal: props.show || false,
          type: props.type,
          name: props.name
        };
    }

    close = (e) => {
        this.setState({
            showModal: false
        });
    }

    confirm = async () => {
        await this.props.onConfirm()
        this.close();
        this.setState(this.getResetState());
    }

    cancel = () => {
        if (this.props.cancel) {
            this.props.cancel();
        }

        this.close();
    }

    render() {
        return (
            <Modal show={this.state.showModal} onHide={this.cancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete confirmation</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        <p>Are you sure you want to delete the <strong>{this.state.name}</strong> {this.state.type}?</p>
                        
                        {this.props.subtext &&
                        <p>{this.props.subtext}</p>
                        }
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="link" onClick={this.cancel}>Cancel</Button>
                    <Button bsStyle="danger" onClick={this.confirm}>Delete</Button>
                </Modal.Footer>
            </Modal>
        );
    }
};

export default DeleteModal;