import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import AccountLeftNav from './left-nav';
import { Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from '../auth/authenticated-route';
import ProfileComponent from './profile';
import BillingComponent from './billing';
import InvoicesComponent from './invoices';

export default class AccountComponent extends Component {
  render () {
    return (
      <Grid>
        <Row>
          <Col lg={2}>
            <AccountLeftNav />
          </Col>

          <Col lg={10}>
            <Switch>
              <AuthenticatedRoute path='/account/profile' component={ProfileComponent} />
              <AuthenticatedRoute path='/account/billing' component={BillingComponent} />
              <AuthenticatedRoute path='/account/invoices' component={InvoicesComponent} />
              <Redirect path="/account/*" to="/account/profiles" />
            </Switch>
          </Col>
        </Row>
      </Grid>
    );
  }
};