/* global localStorage */

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import _get from 'lodash/get';
import { fetchUser } from '../data/actions';
import authHandler from '../auth/handler.jsx';


class AuthComponent extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      redirect: false
    };
  }
  
  componentDidMount = async () => {
      let action = this.props.match.params.action;
      
      switch (action) {
          case 'callback':
              authHandler.handleAuthentication(async (redirectTo) => {
                await this.props.fetchUser(authHandler.getAccessToken());
                this.setState({
                  redirect: redirectTo || '/apps'
                });
              });
              break;
          
          case 'login':
            authHandler.login(_get(this.props, 'location.state.from', {}));
            break;
            
          case 'logout':
            authHandler.logout();
            break;
      }
  }
  
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    
    return null;
  }
  
}

const mapStateToProps = (state) => {
  return {
    apps: state.apps
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: (token) => dispatch(fetchUser(token))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthComponent);