import React, { Component } from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default class AccountLeftNav extends Component {

    render() {
        return (
            <Nav bsStyle="pills" stacked>
                <LinkContainer to="/account/profile">
                    <NavItem>Profile</NavItem>
                </LinkContainer>

                <LinkContainer to="/account/billing">
                    <NavItem>Billing</NavItem>
                </LinkContainer>

                <LinkContainer to="/account/invoices">
                    <NavItem>Invoices</NavItem>
                </LinkContainer>
            </Nav>
        );
    }

}