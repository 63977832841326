import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { fetchAppIfNeeded, fetchConfig, saveApp, saveConfig, invalidateApp, deleteConfig, selectApp, fetchKeys, regenerateKey } from '../data/actions';
import AppDetail from './app-detail';

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    fetchApp: (app) => dispatch(fetchAppIfNeeded(app)),
    fetchConfig: (app, config) => dispatch(fetchConfig(app, config)),
    saveApp: (app) => dispatch(saveApp(app)),
    saveConfig: (config) => dispatch(saveConfig(config)),
    invalidateApp: () => dispatch(invalidateApp()),
    deleteConfig: (app, env) => dispatch(deleteConfig(app, env)),
    selectApp: (app) => dispatch(selectApp(app)),
    fetchKeys: (appSlug) => dispatch(fetchKeys(appSlug)),
    regenerateKey: (appSlug, envSlug) => dispatch(regenerateKey(appSlug, envSlug))
  };

};

const mapStateToProps = (state, ownProps) => {
  return {
    app: state.selectedApp,
    config: state.selectedConfig,
    keys: state.keys
  };
};

const AppDetailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppDetail);

export default AppDetailContainer;