import '../css/app.css';
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from '../auth/authenticated-route';
import Toolbar from './toolbar.jsx';
import AuthComponent from '../account/auth.jsx';
import AppsComponent from '../apps';
import AccountComponent from '../account';
import Footer from './footer';
//import Login from '../account/login.jsx';
//import Registration from '../account/register.jsx';
//import ForgotPassword from '../account/forgot-password.jsx';

export default () =>
  <>
    <div className="content">
      <Toolbar />
      <Switch>
        <AuthenticatedRoute path='/apps' component={AppsComponent} />
        <AuthenticatedRoute path='/account' component={AccountComponent} />
        <Redirect path="*" to="/apps" />
      </Switch>
    </div>
    <Footer />
  </>
