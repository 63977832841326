import React, {Component, PropTypes} from 'react';
import { Modal, FormControl, Button, ControlLabel, FormGroup, Alert } from 'react-bootstrap';

class CreateEnv extends Component {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            showModal: props.show || false,
            env: '',
            error: void 0
        });
    }

    getResetState = () => {
        return {
            env: '',
            error: void 0
        };
    }

    componentWillReceiveProps = function(nextProps) {
      this.setState({
        showModal: nextProps.show || false
      });
    }
    
    componentDidUpdate = function(prevProps, prevState) {
        if (this.state.showModal && !prevState.showModal && this.envNameInput) {
            this.envNameInput.focus();
        }
    }

    getValidationState = () => {
        const validMatch = this.state.env.search(new RegExp('^[A-Za-z0-9\-_]+$'));
        if (validMatch >= 0) return 'success';
        else if (this.state.env.length === 0) return;
        else return 'error';
    }

    handleNameChange = (e) => {
      this.setState({ env: e.target.value });
    }

    close = (e) => {
        this.setState({
            showModal: false,
            error: void 0
        });
    }
    
    cancel = () => {
        this.props.cancel && this.props.cancel();
        this.close();
    }

    save = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        try {
            await this.props.save(this.state);
            this.close();
            this.setState(this.getResetState());
        } catch (err) {
            this.setState({
                error: err.response && err.response.status === 402 ? err.response.status : err.message
            });
        }
    }

    render() {
        return (
            <Modal show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>Create an environment</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Enter a name for this environment.</p>
                    <form onSubmit={this.save}>
                        <FormGroup controlId="formEnvName" validationState={this.getValidationState()}>
                          <ControlLabel>Name</ControlLabel>
                          <FormControl
                            inputRef={(input) => this.envNameInput = input}
                            type="text"
                            value={this.state.env}
                            placeholder="Alphanumeric characters, hyphens, and underscores only"
                            onChange={this.handleNameChange}
                            autoFocus={true}
                          />
                          <FormControl.Feedback />
                        </FormGroup>
                    </form>
                    
                    {this.state.error && <Alert bsStyle="danger">
                        <strong>Error: </strong>
                        { this.state.error === 402 && <span>You'll need a valid payment method on file. <a href="/account/billing">Add a card</a> to continue.</span> }
                        { typeof this.state.error === 'string' && this.state.error }
                    </Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="cancel" onClick={this.cancel}>Cancel</Button>
                    <Button bsStyle="primary" onClick={this.save} disabled={this.getValidationState() === 'error'}>Create</Button>
                </Modal.Footer>
            </Modal>
        );
    }
};

export default CreateEnv;