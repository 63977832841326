import React, { Component } from 'react';
import { connect } from 'react-redux';
import Time from 'react-time';
import {FormattedNumber} from 'react-intl';
import { fetchInvoices } from '../data/actions';

import { Table, Button, Glyphicon, PageHeader, PanelGroup, Panel } from 'react-bootstrap';

class AccountInvoices extends Component {

    constructor(props) {
        super(props);

        props.fetchInvoices();

        this.state = {};
    }

    render() {
        return (
            <div>
                <PageHeader>
                    Invoices
                </PageHeader>
                <p>The past 12 months of invoices are listed below. Select one to view more detail.</p>
                <PanelGroup>
                        { this.props.invoices.isLoading && <Panel>Loading...</Panel> }
                        { !this.props.invoices.isLoading && this.props.invoices.items.length === 0 &&
                            <Panel>
                                No invoices found
                            </Panel>
                        }

                        { !this.props.invoices.isLoading && this.props.invoices.items.map(invoice => {
                            let invoiceDate = new Date(0);
                            invoiceDate.setUTCSeconds(invoice.date);
                            return (
                                <Panel collapsible key={invoice.id} className="invoices-panel">
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            <div className="invoices-panel-item invoices-panel-date"><Time value={invoiceDate} format="YYYY/MM/DD" /></div>
                                            <div className="invoices-panel-item invoices-panel-amount"><FormattedNumber value={invoice.amount_due / 100} currency={invoice.currency} style='currency' /></div>
                                            <div className="invoices-panel-item invoices-panel-status">{ invoice.paid && <span style={{color: 'green'}}>Paid</span> } { !invoice.paid && <span>Pending</span> }</div>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            <Table striped>
                                                <thead>
                                                    <tr>
                                                        <th>Item</th>
                                                        <th>Quantity</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                {invoice.lines.data.map(line => {
                                                    return (
                                                        <tr>
                                                            <td>{line.plan.nickname}</td>
                                                            <td>{line.quantity}</td>
                                                            <td><FormattedNumber value={line.amount / 100} currency={invoice.currency} style='currency' /></td>
                                                        </tr>
                                                    );
                                                })}
                                                </tbody>
                                            </Table>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            );
                        }) }
                </PanelGroup>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        invoices: state.invoices
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchInvoices: () => dispatch(fetchInvoices())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountInvoices);