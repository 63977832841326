import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invalidateCards, fetchCards, saveCard, deleteCard, saveDefaultCard, fetchCustomer } from '../data/actions';

import { Table, Button, Glyphicon, PageHeader } from 'react-bootstrap';
import {Elements, CardElement} from 'react-stripe-elements';
import NewCard from './new-card.jsx';
import DeleteModal from '../common/delete-modal.jsx';

class AccountBilling extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAddingCard: false,
            isDeletingCard: false,
            selectedCard: {}
        };
    }

    componentDidMount() {
        this.props.fetchCards();
        this.props.fetchCustomer();
    }

    showNewCardDialog = () => {
        this.setState({isAddingCard: true});
    }

    saveNewCard = (token) => {
        return this.props.saveCard(token)
            .then(() => {
                this.setState({
                isAddingCard: false
              });
            });
    }

    showDeleteCardDialog = (card) => {
        this.setState({
          isDeletingCard: true,
          selectedCard: card
        });
    }

    deleteCard = () => {
        this.props.deleteCard(this.state.selectedCard.id);
        this.setState({
            isDeletingCard: false
        });
    }

    cancelDelete = () => {
        this.setState({
            isDeletingCard: false
        });
    }
    
    cancelSave = () => {
        this.setState({
            isAddingCard: false
        });
    }

    render() {
        return (
            <div>
                <PageHeader>
                    Payment methods
                    <Button bsStyle="success" className="pull-right" onClick={this.showNewCardDialog}>
                        <Glyphicon glyph="plus" /> Add card
                    </Button>
                </PageHeader>
                <p>Add or manage payment methods. All major credit and debit cards are accepted.</p>
                <Table responsive>
                    <thead>
                        <tr>
                          <th>Card</th>
                          <th>Expires</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        { this.props.cards.isLoading && <tr><td colSpan="3">Loading...</td></tr> }
                        { !this.props.cards.isLoading && this.props.cards.items.length === 0 &&
                            <tr>
                                <td colSpan="3">Add a new card</td>
                            </tr>
                        }

                        { !this.props.cards.isLoading && this.props.cards.items.map(card => {
                            return (
                                <tr key={card.id}>
                                    <td>{card.brand} {card.last4} ({card.funding}) {card.id === this.props.cards.default_card ? '(default)' : ''}</td>
                                    <td>{card.exp_month} / {card.exp_year}</td>
                                    <td>
                                        <a className="pull-right delete-link icon-link" onClick={this.showDeleteCardDialog.bind(this, card)}>
                                          <Glyphicon glyph="remove" /> Delete
                                        </a>

                                        {card.id !== this.props.cards.default_card &&
                                        <a className="pull-right icon-link" style={{marginRight: '10px'}} onClick={this.props.setDefaultCard.bind(null, card.id)}>
                                            <Glyphicon glyph="ok" /> Set default
                                        </a>
                                        }
                                    </td>
                                </tr>
                            );
                        }) }
                      </tbody>
                </Table>
                <Elements>
                    <NewCard show={this.state.isAddingCard} handleSaveCard={this.saveNewCard} cancel={this.cancelSave} />
                </Elements>
                <DeleteModal show={this.state.isDeletingCard} name={`${this.state.selectedCard.brand} ${this.state.selectedCard.last4} (${this.state.selectedCard.funding})`}
                             type="card" onConfirm={this.deleteCard} cancel={this.cancelDelete}
                             subtext={this.props.cards.items.length === 1 && <strong style={{color: 'red'}}>You are about to remove the only payment method attached to your account. Proceeding may result in disruption of service!</strong> || null} />
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        cards: state.cards
    };
}

function mapDispatchToProps(dispatch) {
    return {
        invalidateCards: () => dispatch(invalidateCards()),
        fetchCards: () => dispatch(fetchCards()),
        saveCard: (token) => dispatch(saveCard(token)),
        deleteCard: (cardId) => dispatch(deleteCard(cardId)),
        setDefaultCard: (cardId) => dispatch(saveDefaultCard(cardId)),
        fetchCustomer: () => dispatch(fetchCustomer())
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountBilling);