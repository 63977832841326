export function defaultState(fn) {
    let state = {
        isLoading: true,
        error: null,
        ...(typeof fn !== 'function' && fn),
    };
    
    if (typeof fn === 'function') {
        return fn(state);
    }
    
    return state;
}

export function defaultOnRequest(fn) {
    
    return (state, action) => {
        let newState = {
            ...state,
            ...(typeof fn !== 'function' && fn),
            isLoading: true,
            error: null
            
        };

        return typeof fn === 'function' ? fn(newState, action) : newState;
    };
}

export function defaultOnResponse(fn) {
    return (state, action) => {
        let newState = {
            ...(typeof fn !== 'function' && fn),
            ...state,
            isLoading: false,
            error: action.error ? action.payload : null
        };

        return typeof fn === 'function' ? fn(newState, action) : newState;
    };
}
