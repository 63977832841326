import React, {Component, PropTypes} from 'react';
import { Modal, FormControl, Button, ControlLabel, FormGroup, Alert } from 'react-bootstrap';

class CreateApp extends Component {

    constructor(props) {
        super(props);

        this.state = this.getResetState(props);
    }

    getResetState = (props) => {

        if (props.app) {
            return {
                name: props.app.name || '',
                description: props.app.description || '',
                mode: 'edit',
                showModal: props.show || false,
                error: void 0,
                saving: false
            };
        }

        return {
            name: '',
            description: '',
            mode: 'create',
            showModal: props.show || false,
            error: void 0,
            saving: false
        };
    }

    componentWillReceiveProps = function(nextProps) {
      this.setState(this.getResetState(nextProps));
    }
    
    componentDidUpdate = function(prevProps, prevState) {
        if (this.state.showModal && !prevState.showModal && this.nameInput) {
            this.nameInput.focus();
        }
    }

    getValidationState = () => {
        const length = this.state.name.length;
        if (length > 0) return 'success';
        else if (length > 50) return 'error';
    }

    handleNameChange = (e) => {
      this.setState({ name: e.target.value });
    }

    handleDescChange = (e) => {
      this.setState({ description: e.target.value });
    }

    close = (e) => {
        this.setState({
            showModal: false
        });
    }
    
    cancel = () => {
        this.props.cancel && this.props.cancel();
        this.close();
    }

    save = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ saving: true });
        
        try {
            await this.props.save(this.state);
            this.close();
            this.setState(this.getResetState({}));
        } catch (err) {
            this.setState({
                error: err.response && err.response.status === 402 ? err.response.status : err.message,
                saving: false
            });
        }
    }

    render() {
        let {
            mode,
            saving
        } = this.state;

        let btnText;
        if (saving) {
            btnText = mode === 'create' ? 'Creating...' : 'Saving...';
        } else {
            btnText = mode === 'create' ? 'Create' : 'Save';
        }

        return (
            <Modal show={this.state.showModal} onHide={this.close}>
                <Modal.Header closeButton>
                    <Modal.Title>{mode === 'create' ? 'Create a new app' : 'Edit existing app'}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { mode === 'create' && <p>Creating an app enables you to define configurations for each of your app's environments.</p> }
                    { mode === 'edit' && <p>Make changes below to your app's name and/or description.</p> }
                    <form onSubmit={this.save}>
                        <FormGroup controlId="formAppName" validationState={this.getValidationState()}>
                          <ControlLabel>Name</ControlLabel>
                          <FormControl
                            type="text"
                            value={this.state.name}
                            placeholder="Enter a name that identifies the app"
                            onChange={this.handleNameChange}
                            inputRef={(input) => { this.nameInput = input; }} 
                          />
                          <FormControl.Feedback />
                        </FormGroup>

                        <FormGroup controlId="formAppDesc">
                            <ControlLabel>Description</ControlLabel>
                            <FormControl
                              componentClass="textarea"
                              value={this.state.description}
                              placeholder="(optional) Enter a short summary describing the app"
                              onChange={this.handleDescChange}
                            />
                        </FormGroup>
                    </form>

                    {this.state.error && <Alert bsStyle="danger">
                        <strong>Error: </strong>
                        { this.state.error === 402 && <span>You'll need a valid payment method on file. <a href="/account/billing">Add a card</a> to continue.</span> }
                        { typeof this.state.error === 'string' && this.state.error }
                    </Alert>}
                </Modal.Body>

                <Modal.Footer>
                    <Button bsStyle="link" onClick={this.cancel}>Cancel</Button>
                    <Button bsStyle="primary" onClick={this.save} disabled={this.state.saving}>
                        { btnText }
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CreateApp;