import { combineReducers } from 'redux'
import {
  SELECT_APP, INVALIDATE_APP,
  REQUEST_APPS, RECEIVE_APPS,
  REQUEST_APP, RECEIVE_APP,
  REQUEST_CONFIGS, RECEIVE_CONFIGS,
  REQUEST_CONFIG, RECEIVE_CONFIG,
  ADD_APP
} from './actions';

import * as ActionTypes from './actions';

import user from './reducers/user';

function account(state = {
  customer: {
    id: null,
    default_source: null,
    currency: 'usd'
  },
  isLoading: true,
  didInvalidate: true
}, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_CUSTOMER:
      return Object.assign({}, state, { isLoading: true, didInvalidate: true });

    case ActionTypes.RECEIVE_CUSTOMER:
      return Object.assign({}, state, { isLoading: false, didInvalidate: false, customer: action.customer });

    default:
      return state;
  }
}

function selectedApp(state = {
  configs: [],
  isFetching: false,
  didInvalidate: false
}, action) {
  switch (action.type) {
    case REQUEST_APP:
      return Object.assign({}, state, {
        isFetching: true,
        slug: action.slug
      });
    case SELECT_APP:
      return action.app;
    case RECEIVE_APP:
      let app = action.app;
      if (app.configs) {
        let configs = app.configs;
        let baseConfig = configs.splice(configs.findIndex(config => config.env === 'base'), 1)[0];
        configs.unshift(baseConfig);
        app.configs = configs;
      }
      
      return Object.assign({}, state, app, {
        isFetching: false
      });
    case INVALIDATE_APP:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_CONFIGS:
      return Object.assign({}, state, {
        isFetching: true,
        configs: []
      });
    case RECEIVE_CONFIGS:
      let configs = action.configs;
      let baseConfig = configs.splice(configs.findIndex(config => config.env === 'base'), 1)[0];
      configs.unshift(baseConfig);
      
      return Object.assign({}, state, {
        configs: configs,
        isFetching: false
      });
  default:
    return state;
  }
}

function selectedConfig(state = {
  isFetching: false
}, action) {
  switch (action.type) {
    case REQUEST_CONFIG:
      return Object.assign({}, state, {
        isFetching: true,
        env: action.env
      });
    case RECEIVE_CONFIG:
      return Object.assign({}, state, action.config, {
        isFetching: false
      });
    default:
      return state;
  }
}

function apps(state = {
  isFetching: false,
  didInvalidate: false,
  items: []
}, action) {
  switch (action.type) {
    case ADD_APP:
      return Object.assign({}, {
        items: [
          ...state.items,
          action.app
        ]
      });
    case RECEIVE_APPS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: action.apps,
        lastUpdated: action.receivedAt
      });

    case REQUEST_APPS:
      return Object.assign({}, state, {
        isFetching: true
      });
    default:
      return state;
  }
}

function keys(state = {
  isFetching: false,
  didInvalidate: false,
  appKey: void 0,
  items: {}
}, action) {
  switch (action.type) {
    case ActionTypes.RECEIVE_KEYS:
      
      let newState = Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        items: {}
      });
      
      // Put keys into a dict
      if (action.keys) {
        action.keys.forEach(key => {
          if (key.type === 'app') {
            newState.appKey = key;
          } else {
            newState.items[key.env] = key;
          }
        });
      }
      
      return newState;
      
    case ActionTypes.REQUEST_KEYS:
      return Object.assign({}, state, {
        isFetching: true,
        appKey: void 0,
        items: {}
      });
      
    default:
      return state;
  }
}

function cards(state = {
  isLoading: true,
  didInvalidate: true,
  default_card: null,
  items: []
}, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_CARDS:
      return Object.assign({}, state, {isLoading: true});

    case ActionTypes.RECEIVE_CARDS:
      return Object.assign({}, state, {isLoading: false, didInvalidate: false, items: action.payload || []});

    case ActionTypes.SET_DEFAULT_CARD:
      return Object.assign({}, state, {default_card: action.cardId});

    default:
      return state;
  }
}

function invoices(state = {
  isLoading: true,
  didInvalidate: true,
  items: []
}, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_INVOICES:
      return Object.assign({}, state, {isLoading: true});

    case ActionTypes.RECEIVE_INVOICES:
      return Object.assign({}, state, {isLoading: false, didInvalidate: false, items: action.payload});

    default:
      return state;
  }
}

const rootReducer = combineReducers({
  selectedApp,
  selectedConfig,
  apps,
  cards,
  invoices,
  account,
  keys,
  user
});

export default rootReducer;