import { createAsyncMiddleware } from 'redux-arc';
import fetch from 'isomorphic-fetch';
import authHandler from '../auth/handler';

export default function apiFetch(url, opts = {}) {
    let options = {
        ...opts,
        headers: {
            ...opts.headers,
            authorization: `Bearer ${authHandler.getAccessToken()}`
        }
    };

    return fetch(url, options);
}

const apiInvoke = store => done => async (options) => {
    const { method, url, payload } = options;
    const params = method === 'get' ? { params: payload } : payload;

    let targetUrl = url;

    let requestOpts = {
        method,
        headers: {
            ...(options && options.headers),
            authorization: `Bearer ${authHandler.getAccessToken()}`,
        },
    };

    if (method !== 'get' && payload) {
        requestOpts.headers['content-type'] = 'application/json';
        requestOpts.body = JSON.stringify(payload);
    }

    try {
        let resp = await fetch(targetUrl, requestOpts);

        let body;
        if (!resp.headers.get('content-type').includes('json')) {
            body = await resp.text();
        } else {
            body = await resp.json();
        }

        if (!resp.ok) {
            return done(body, null);
        }

        return done(null, body);
    } catch (err) {
        return done(err, null);
    }
};

export const reduxFetchMiddleware = createAsyncMiddleware(apiInvoke);