import fetch from './fetch';
import {processResponse} from './utils';
import { createActions } from 'redux-arc';

export const REQUEST_APPS = 'REQUEST_APPS';
export const RECEIVE_APPS = 'RECEIVE_APPS';
export const REQUEST_APP = 'REQUEST_APP';
export const RECEIVE_APP = 'RECEIVE_APP';
export const SELECT_APP = 'SELECT_APP';
export const ADD_APP = 'ADD_APP';
export const INVALIDATE_APP = 'INVALIDATE_APP';
export const REQUEST_CONFIGS = 'REQUEST_CONFIGS';
export const RECEIVE_CONFIGS = 'RECEIVE_CONFIGS';
export const REQUEST_CONFIG = 'REQUEST_CONFIG';
export const RECEIVE_CONFIG = 'RECEIVE_CONFIG';
export const REQUEST_CARDS = 'REQUEST_CARDS';
export const RECEIVE_CARDS = 'RECEIVE_CARDS';
export const INVALIDATE_CARDS = 'INVALIDATE_CARDS';
export const REQUEST_INVOICES = 'REQUEST_INVOICES';
export const RECEIVE_INVOICES = 'RECEIVE_INVOICES';
export const SET_DEFAULT_CARD = 'SET_DEFAULT_CARD';
export const REQUEST_KEYS = 'REQUEST_KEYS';
export const RECEIVE_KEYS = 'RECEIVE_KEYS';
export const REQUEST_CUSTOMER = 'REQUEST_CUSTOMER';
export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER';
export const RECEIVE_USER = 'RECEIVE_USER';

export function saveApp(app) {

  var method = app.id ? 'PUT' : 'POST';
  var url = app.id ? `/api/apps/${app.slug}` : '/api/apps';

  return async (dispatch) => {
    let response = await fetch(url, {
      method,
      credentials: 'include',
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(app)
    });

    let body = await processResponse(response);
    dispatch(fetchApps());

    return body;
  };

}

function addApp(app) {
  return {
    type: ADD_APP,
    app
  };
}

export function selectApp(app) {
  return {
    type: SELECT_APP,
    app
  };
}

function requestApps() {
  return {
    type: REQUEST_APPS
  };
}

function receiveApps(json) {
  return {
    type: RECEIVE_APPS,
    apps: json,
    receivedAt: Date.now()
  };
}

export function fetchApps() {
  return (dispatch, getState) => {

    if (getState().apps.isFetching) {
      return;
    }

    dispatch(requestApps());
    return fetch(`/api/apps`, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(json => dispatch(receiveApps(json)));
  };
}

function requestApp(slug) {
  return {
    type: REQUEST_APP,
    slug
  };
}

function receiveApp(json) {
  return {
    type: RECEIVE_APP,
    app: json,
    receivedAt: Date.now()
  };
}

export function invalidateApp() {
  return {
    type: INVALIDATE_APP
  };
}

function requestConfig(env) {
  return {
    type: REQUEST_CONFIG,
    env
  };
}

function receiveConfig(json) {
  return {
    type: RECEIVE_CONFIG,
    config: json,
    receivedAt: Date.now()
  };
}

function requestConfigs(app) {
  return {
    type: REQUEST_CONFIGS,
    app
  };
}

function receiveConfigs(configs) {
  return {
    type: RECEIVE_CONFIGS,
    configs
  };
}

function requestCards() {
  return {
    type: REQUEST_CARDS
  };
}

function receiveCards(payload) {
  return {
    type: RECEIVE_CARDS,
    payload
  };
}

function setDefaultCard(cardId) {
  return {
    type: SET_DEFAULT_CARD,
    cardId
  };
}

export function invalidateCards() {
  return {
    type: INVALIDATE_CARDS
  };
}

function requestInvoices() {
  return {
    type: REQUEST_INVOICES
  };
}

function receiveInvoices(payload) {
  return {
    type: RECEIVE_INVOICES,
    payload: payload.data
  };
}

function requestCustomer() {
  return {
    type: REQUEST_CUSTOMER
  };
}

function receiveCustomer(customer) {
  return {
    type: RECEIVE_CUSTOMER,
    customer
  };
}

function requestKeys() {
  return {
    type: REQUEST_KEYS
  };
}

function receiveKeys(payload) {
  return {
    type: RECEIVE_KEYS,
    keys: payload
  };
}

function receiveUser(payload) {
  return {
    type: RECEIVE_USER,
    payload
  }
}

/*************************************
 * Async actions (i.e. side-effects) *
 *************************************/

export function fetchApp(slug) {
  return (dispatch, getState) => {

    if (getState().selectedApp.isFetching) {
      return;
    }

    dispatch(requestApp(slug));

    return fetch(`/api/apps/${slug}`, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(json => {
        return fetch(`/api/apps/${slug}/configs`, {
          credentials: 'include'
        })
        .then(response => response.json())
        .then(configs => {
          json.configs = configs;
          return json;
        });
      })
      .then(json => dispatch(receiveApp(json)));
  };
}

export function fetchConfigs(app) {
  return dispatch => {
    dispatch(requestConfigs(app));

    return fetch(`/api/apps/${app}/configs`, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(json => dispatch(receiveConfigs(json)));
  };
}

export function fetchConfig(app, config) {
  return dispatch => {
    dispatch(requestConfig(config));

    return fetch(`/api/apps/${app}/configs/${config}`, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(json => dispatch(receiveConfig(json)));
  };
}

export function saveConfig(config) {

  var method = 'POST';
  var url = `/api/apps/${config.app}/configs`;

  if (config.id) {
    method = 'PUT';
    url += `/${config.env}`;
  }

  return async (dispatch) => {
    dispatch(requestConfig(config.env));

    let response = await fetch(url, {
      method,
      credentials: 'include',
      headers: {
        'accept': 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(config)
    });
    
    let body = await processResponse(response);
    
    dispatch(receiveConfig(body));

    if (method === 'POST') {
      dispatch(fetchKeys(config.app));
    }
  };
}

export function deleteConfig(app, env) {
  return dispatch => {
    return fetch(`/api/apps/${app}/configs/${env}`, {
      method: 'delete',
      credentials: 'include'
    });
  };
}

function shouldFetchApp(state, slug) {
  const app = state.selectedApp;

  if (!app || app.slug !== slug) {
    return true;
  } else if (app.isFetching) {
    return false;
  } else {
    return app.didInvalidate;
  }
}

export function fetchAppIfNeeded(app) {
  return (dispatch, getState) => {
    if (shouldFetchApp(getState(), app)) {
      return dispatch(fetchApp(app));
    }
  };
}

export function fetchCards() {
  return dispatch => {
    dispatch(requestCards());

    return fetch(`/api/accounts/cards`, {
      method: 'get',
      credentials: 'include'
    })
    .then(response => response.json())
    .then(json => dispatch(receiveCards(json)));
  };
}

export function saveCard(stripeToken) {
  return async (dispatch) => {

    let response = await fetch(`/api/accounts/cards`, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(stripeToken)
    });
    
    await processResponse(response);
    
    dispatch(fetchCards());
  };
}

export function deleteCard(cardId) {
  return dispatch => {
    return fetch(`/api/accounts/cards/${cardId}`, {
      method: 'delete',
      credentials: 'include'
    })
    .then(response => dispatch(fetchCards()));
  };
}

export function saveDefaultCard(cardId) {
  return dispatch => {
    return fetch(`/api/accounts/cards/${cardId}`, {
      method: 'put',
      credentials: 'include'
    })
    .then(response => dispatch(setDefaultCard(cardId)));
  };
}

export function fetchInvoices() {
  return dispatch => {
    dispatch(requestInvoices());

    return fetch(`/api/accounts/invoices`, {
      method: 'get',
      credentials: 'include'
    })
    .then(response => response.json())
    .then(json => dispatch(receiveInvoices(json)));
  };
}

export function fetchCustomer() {
  return dispatch => {
    dispatch(requestCustomer());

    return fetch(`/api/accounts/customer`, {
      method: 'get',
      credentials: 'include'
    })
    .then(response => response.json())
    .then(json => {
      dispatch(receiveCustomer(json));
      dispatch(setDefaultCard(json.default_source));
    });
  };
}

export function deleteApp(slug) {
  return dispatch => {
    return fetch(`/api/apps/${slug}`, {
      method: 'delete',
      credentials: 'include'
    })
    .then(() => dispatch(fetchApps()));
  };
}

export function fetchKeys(app) {
  return dispatch => {
    dispatch(requestKeys());
    
    return fetch(`/api/apps/${app}/keys`, {
      method: 'get',
      credentials: 'include'
    })
    .then(response => response.json())
    .then(json => dispatch(receiveKeys(json)));
  };
}

export function regenerateKey(app, env) {
  return dispatch => {
    
    let queryString = '';
    if (env) {
      queryString += `?env=${env}`;
    }
    
    return fetch(`/api/apps/${app}/keys${queryString}`, {
      method: 'put',
      credentials: 'include',
    })
    .then(() => dispatch(fetchKeys(app)));
  };
}

export const userActions = createActions('user', {
  fetch: { url: '/api/me', method: 'get' },
  update: { url: '/api/me', method: 'put' },
});

export function fetchUser(token) {
  return async dispatch => {
    dispatch(userActions.creators.fetch());
  }
  
}
