import React, { Component } from 'react';
import Time from 'react-time';
import { Button, Glyphicon, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import brace from 'brace';
import AceEditor from 'react-ace';
import _get from 'lodash/get';

import 'brace/mode/json';
import 'brace/theme/tomorrow_night';

class AppConfigEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasChanged: false,
            configValue: this.configAsString(props.config.json || {}, true)
        };
    }

    componentWillReceiveProps = (newProps) => {
        this.onConfigChange(this.configAsString(newProps.config.json, true), newProps);
    }

    configAsString(obj, humanReadable) {
        if (humanReadable) {
            return JSON.stringify(obj, null, 4);
        }
        
        return JSON.stringify(obj);
    }

    save = () => {
        this.props.onSave('config', Object.assign(this.props.config, {
            json: JSON.parse(this.state.configValue)
        }));
    }

    onConfigChange = (value, props) => {
        if (!props) {
            props = this.props;
        }
        
        try {
            let normalizedValue = this.configAsString(JSON.parse(value));
            this.setState({
                hasChanged: normalizedValue !== this.configAsString(props.config.json),
                configValue: value
            });
        } catch (err) {
            // No-op
        }
    }

      render () {

          let {
              isFetching
          } = this.props;

          let {
              hasChanged
          } = this.state;

          let statusText = 'Unsaved changes';
          if (!hasChanged) {
              statusText = 'Saved';
          } else if (isFetching) {
              statusText = 'Saving';
          }


        return (
            <div>
                <p>Use the editor below to build an environment config. Currently, the editor only supports JSON formatted documents.
                   Save your changes when done.</p>
                <AceEditor
                    ref={editor => { this.aceEditorRef = editor; }}
                    width="100%"
                    mode="json"
                    theme="tomorrow_night"
                    className="editor"
                    name="app-config-editor"
                    editorProps={{$blockScrolling: true}}
                    onChange={(value) => this.onConfigChange(value)}
                    value={this.state.configValue}
                />

                <Row className="show-grid">
                    <Col xs={12}>
                        <span>{statusText}</span>
                        <Button bsStyle="primary" className="pull-right" onClick={this.save} disabled={!hasChanged}>Save</Button>
                    </Col>
                </Row>
           </div>
        );
      }
};

export default AppConfigEditor;