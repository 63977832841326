export async function processResponse(response) {
    let headers = response.headers;
    let contentType = headers.get('content-type');

    let body = contentType.includes('json') ? await response.json() : await response.text();

    if (!response.ok) {
        let err = new Error((body.error && body.error.message) || body);
        err.response = response;
        err.body = body;
        throw err;
    }

    return body;
}

export function getQueryVariable(variable, str) {
    var query = str || window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}