import { createHash } from 'crypto';
import { createReducers } from 'redux-arc';
import { defaultState, defaultOnRequest, defaultOnResponse } from '../reducer_templates';
import { userActions } from '../actions';

function onUpdateUser(state, action) {
  if (action.error) { return state; }

  if (action.payload.email !== null) {
    action.payload.photo = 'https://gravatar.com/avatar/' + createHash('md5').update(action.payload.email.trim().toLowerCase()).digest('hex');
  }

  return {
    ...state,
    profile: action.payload
  };
}

const userHandlers = {
  [userActions.types.FETCH.REQUEST]: defaultOnRequest({ profile: null }),
  [userActions.types.FETCH.RESPONSE]: defaultOnResponse(onUpdateUser),
  [userActions.types.UPDATE.REQUEST]: defaultOnRequest({ profile: null }),
  [userActions.types.UPDATE.RESPONSE]: defaultOnResponse(onUpdateUser)
};

export default createReducers(defaultState({ profile: null }), userHandlers);